

/*-----------------------------
    VARIABLE DE COULEUR DU THEME
-------------------------------*/
.slogan {
    color:#fff;
}

nav#nav-principal ul li a, .menu_burger{
    color:#fff;
}
nav#nav-principal ul li ul li a{
    color:#fff;
}

nav#nav-principal ul li ul li:hover {
    background: none repeat scroll 0 0 #FFFFFF;
    color: #1D1D33;
    transition: all 0.1s linear 0s;
}

.produits .produit_etiquette:after {
    border-color:rgb(29, 29, 51)rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);
  
}
.produits .produit_etiquette {
    background:rgb(29, 29, 51);
}
.produits .produit_etiquette2 {
    background:rgb(29, 29, 51);
}
.produits .produit_etiquette2:after {
  border-color: rgba(0, 0, 0, 0)rgb(13, 13, 22) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
 
  }
nav#nav-principal ul li ul li:hover > a{
    color:#fff;
}
.title_section {
    color:#fff;
}
.title_section a, h2, .h2 {
    color:#fff;
    a{
        color: inherit;
    }
}
.mask > h3,
.mask > .h3 {
    color:#fff;
}
.mask .addcommentaires{
    color:#fff;
}
.content #fiche-produit .wrap-details h4,
.content #fiche-produit .wrap-details .h4{
    color:#fff;
}
.content #fiche-produit .remise-produit{
    background:#fff;
}
.bloc-adresse .adresse{
    color:#fff;
}
.adresse a{
    color:#fff;
}
.mention {
    color:#fff;
}

.mention a{
    color:#fff;
}
.iview-caption a{
    color:#fff;
    
}
.iview-caption {
    color:#fff;
}
.iview-block, .iview-strip{
    background-color: #fff !important;
}

.button, .produits .addbasket, .addmsglo, .zoom {
    color:#fff;
}

.button:hover, .produits .addbasket:hover, .addmsglo:hover, .zoom:hover{
    background: #fff;
}
.header_color_p, .header_p{
    background:#1D1D33;
}



nav#nav-principal ul li ul li{
    background:#1D1D33;
    border-right-color:#1D1D33;
    border-left-color:#1D1D33;
    border-top-color: #1D1D33;
}

nav#nav-principal ul li ul li:last-child{
    border-bottom-color: #1D1D33;
}

nav#nav-principal ul ul:after{
    border-color: transparent transparent #1D1D33;
}

nav#nav-principal ul ul ul:after{
    border-right-color: #1D1D33;
}

nav.secondaire ul li a{
    color:#1D1D33;
}

nav#nav-principal ul .left-side ul:after{
    border-left-color: #1D1D33;
}

body{
    color:#1D1D33;
}

p{
    //color:#1D1D33;
}

#contentgalerie p{
    //color:#1D1D33;
}

.produits .prixprod {
    //color:#1D1D33;
}

.produits .oldprix {
    //color: #1D1D33;
}

.details{
    //color:#1D1D33;
}

.fil-dariane a{
    color:#1D1D33;
}

.fil-dariane:hover{
    color:#1D1D33;
}

.description{
    //color:#1D1D33;
}

.content #fiche-produit .wrap-description .prix .oldprix {
    //color:#1D1D33;
}

.form label {
    //color:#1D1D33;
}

#footer { 
    background:#1D1D33; 
}

.iview-controlNav div.iview-items ul li { 
    background:#1D1D33;
}

.contact_text {
    //color:#1D1D33;
}

/* COULEUR 2 */

nav#nav-principal > ul > li:hover > a, nav#nav-principal ul li a:active{
    color:#fff;
}

nav#nav-principal ul li ul li:hover{
background:#1D1D33;
}

.title_section, h2, .h2 {
    background: #1D1D33;
    border-color:transparent;
}

 a{
    color:#1D1D33;
}

.photo {
    border-bottom-color:#1D1D33;
}

.mask > .commentaire {
    border-bottom-color: #1D1D33;
}

.message_lo {
    border-bottom-color:#1D1D33;
}

.message_lo .web a {
    color:#1D1D33;
}

.message_lo .note {
    border-color: #1D1D33;
    //color:#1D1D33;
}

.produits img {
    border-bottom-color: #1D1D33;
}
.produits .nomprod {
    //color:#1D1D33;
}

.produits .remise {
    //color:#1D1D33;
}

.produits select {
    border-color:#1D1D33;
}

.content #fiche-produit .wrap-details h4,
.content #fiche-produit .wrap-details .h4{
    background:#1D1D33;
}

.quantite{
    border-color:#1D1D33;
}

.declinaison select{
    border-color:#1D1D33;
}

.description{
    border-bottom-color:#1D1D33;
}

.prixprod{
    //color:#1D1D33;
    
}

.wrap-description > h3,
.wrap-description > .h3{
    color:#1D1D33;
}

.content #fiche-produit .remise-produit{
    color:#1D1D33;
}

.form fieldset textarea{
    border-color:#1D1D33;
}

.form input {
    border-color:#1D1D33;
}

#GoogleMapAffichage {
    border-color:#1D1D33;
}

a.iview-control.active {
    background:#1D1D33;
}

.iview-controlNav div.iview-items ul li:hover, .iview-controlNav div.iview-items ul li:active{
    background:#1D1D33;
}

.button, .produits .addbasket, .addmsglo, .zoom {background:#1D1D33;border-color:#1D1D33;}
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {background-color: #1D1D33; border-color: #1D1D33;}.pagination > li > a, .pagination > li > span{color:#1D1D33;}
.pagination.pull-right li.active a{color:#fff !important;}
.row.two-column-blog .apercu-article h2.titre-article, .row.two-column-blog .apercu-article .h2.titre-article, .single-article .titre-article h2, .single-article .titre-article .h2, .apercu-article h2.titre-article-no-image, .apercu-article .h2.titre-article-no-image {color:#000 !important;}

.button:hover, .produits .addbasket:hover, .addmsglo:hover, .zoom:hover{
    border-color:#1D1D33;
    color:#1D1D33;
}

.contact_text {
    border-color: #1D1D33;
}


.background-adr{
    background:#1D1D33;
}

.iview-caption {
    background:#1D1D33;
}

.triangle{
    border-left-color:#fff;
    
}

.diaporama:hover .triangle{
    border-left-color:#1D1D33;
}

#wrapper{
    background:#fff;
}

/*-----------------------------
    ENTETE DE PAGE 
-------------------------------*/

/*-----------------------------
    NAVIGATION 
-------------------------------*/


/*
    * Fils d'ariane
*/



/*-----------------------------
   ALBUMS PHOTO
-------------------------------*/


/*
    * Bloc des élements d'un produit
*/


/*-----------------------------
    LIVRE D'OR
-------------------------------*/


/*-----------------------------
    FORMULAIRE D'AJOUT DE MESSAGE SUR LE LIVRE D'OR
-------------------------------*/


/*-----------------------------
    PIED DE PAGE
-------------------------------*/


/*
    * Style Slider iView du thème
*/


/*-----------------------------
    GESTION DES CLASSES OBJETS GENERALE
-------------------------------*/
/*
    * Gestion des photos gerer avec fancybox 
    * sur les produits et galerie images
*/

.iview-caption p{
    color:#fff;
}

.footer_p p, .footer_p table td{
    color:#fff;
}
.produit_etiquette, .produit_etiquette2, .produit_etiquette, .produit_etiquette2{
    background:#1D1D33;
}
.produit_etiquette::after, .produit_etiquette2::after{
    border-color: #1D1D33 rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);
}

@media screen and (max-width: 768px){nav#nav-principal > ul li, .menu_burger{background:#1D1D33}}
.dropDown{color:#fff;}

/** Flux Panier **/

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: #1D1D33;
    border-color: #1D1D33;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: #1D1D33;
    color: #1D1D33;

    a, span {
        color: #1D1D33;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}